.reasons {
    display: flex;
   gap: 1rem;
   padding: 0 2rem;
}

.reasons>.left-s {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 1rem;
}
.reasons>.left-s>img{
    object-fit: cover;
}


.left-s>:nth-child(1){
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
}
.left-s>:nth-child(2){
    width: auto;;
   height: 16rem;
   grid-column: 2/4;
}
.left-s>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-s>:nth-child(4){
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;
}


.reasons>.right-s {
    flex: 1 1;
    text-transform: uppercase;;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.reasons>.right-s>span {
    font-weight: bold;
    color: var(--orange);
}
.reasons>.right-s>div {
    font-weight: bold;
    font-size: 3.3rem;
    color: white;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.details-r>div>span {
    font-size: 1.3rem;
    letter-spacing: 2px;
}

.partners{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.partners>span {
    font-size: 1.3rem;
    color: var(--gray);
}
.partners img{
    width: 3rem;
}
.partners>div {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
}

@media screen and (max-width:768px) { 

    .reasons {
        padding: 1rem;
        flex-direction: column;
    }
    
    .reasons>.left-s {
        overflow: hidden;
        grid-auto-rows: auto;
        gap: 0.5rem;
    }
    .reasons>.left-s>img{
        object-fit: cover;
    }
    
    
    .left-s>:nth-child(1){
        width: 7rem;
        height:17rem;
    }
    .left-s>:nth-child(2){
        width: 15rem;
        height: 10rem;
    }
    .left-s>:nth-child(3){
        width: 7rem;
        height: 6rem;
    }
    .left-s>:nth-child(4){
        width: 4rem;
        height: 6rem;
    }
    
    
    .reasons>.right-s {
        flex: 1 1;
        text-transform: uppercase;;
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }
    
    .reasons>.right-s>div {
        font-size: 3rem;
    }
    
    .details-r>div>img{
        width: 1.5rem;
        height: 1.5rem;
    }
    .details-r>div>span {
        font-size: .8rem;
    }
    
    .partners{
        margin-top: 1rem;
    }
    
    .partners>span {
        font-size: 1rem;
    }
    .partners img{
        width: 2rem;
    }
    .partners>div {
        display: flex;
        margin-top: .6rem;
        gap: .6rem;
    }
}