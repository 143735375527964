.programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-headers {
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    font-style: italic;
}

.programs-categories {
    display: flex;
    gap: 1.5rem;
}

.category {
    background-color: var(--gray);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;;
    gap: 1.5rem;
    color: white;
    width: fit-content;
    transition: .4s ease all;
}
.category:hover {
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    cursor: pointer;
}
.category>svg {
    fill: white;
   transform: scale(1.5);
}

.category>:nth-child(2) {
    font-weight: bold;
    font-size: 1.2rem;
}

.category>:nth-child(3) {
    font-weight: 200;
    font-size: .9rem;
    line-height: 20px;
}

.join-now {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-size: .9rem;
}
.join-now>img {
    width: 1rem;
}

@media screen and (max-width:768px) {

    .programs-headers {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        color: white;
        margin-top: 2rem;
    }
    
    .programs-categories {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
    }
    
  

}