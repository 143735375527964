.footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
}
.footer>hr {
    width: 100%;
    border: 1px solid var(--lightgray);
}
.footer>div {
    display: flex;
    gap: 2rem;
    padding: 2rem;
}

.footer>div>img {
    width: 2rem;
    height: 2rem;
}
.footer>img {
    width: 10rem;
    height: 5rem;
}

.footer img:hover {
    cursor: pointer;
}

.footerblur {
    height: 12rem;
    width: 26rem;
    bottom: 0;
    right: 15%;
    filter: blur(200px);
    background-color: red;
}

.footerblur2 {
    height: 12rem;
    width: 26rem;
    bottom: 0;
    left: 15%;
    filter: blur(170px);
    background-color: rgb(225, 115, 0);
}


@media screen and (max-width:768px) { 

    .footer {
        padding: 0;
    }
    .footer>hr {
        width: 100%;
        border: 1px solid var(--lightgray);
    }
    .footer>div {
        gap: 2rem;
        padding: 1rem 2rem;
    }
    
    .footer>div>img {
        width: 1.5rem;
        height: 1.5rem;
    }
    .footer>img {
        width: 8rem;
        height: 4rem;
    }
    
    .footer img:hover {
        cursor: pointer;
    }
    
}